import { Geocode } from '@eo-storefronts/eo-core'
import { GoogleMap as ReactGoogleMap, GoogleMapProps } from '@react-google-maps/api'
import useWindowResize from '~/src/hooks/layout/useWindowResize'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import GoogleMapsUtils from '~/src/utils/GoogleMapsUtils'
import { Constants } from '~/src/helpers/constants'
import { useEoValue } from '~/src/hooks/useEoState'
import { GOOGLE_MAPS_API_STATE, GoogleMapsApiState } from '~/src/stores/google-maps-api'

const defaultOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  panControl: false,
  rotateControl: false,
  scaleControl: false,
  zoomControl: true,
  clickableIcons: false,
  mapId: '5296ad122bb668a5'
}

interface Props extends Omit<GoogleMapProps, 'mapContainerStyle' | 'center' | 'zoom'> {
  id: string,
  geocodes: Geocode[],
  children: ReactNode,
}

const GoogleMap = ({ id, children, geocodes, options = defaultOptions, ...otherProps }: Props) => {
  const { isLoaded } = useEoValue<GoogleMapsApiState>(GOOGLE_MAPS_API_STATE)
  const { windowSize } = useWindowResize()
  const [ map, setMap ] = useState<google.maps.Map | undefined>()

  const _handleOnLoad = useCallback((map: google.maps.Map) => setMap(map), [])

  const _areAllGeocodesTheSame = useMemo(() => {
    if (!geocodes.length) {
      return true // Empty array, so all values are considered the same
    }

    return geocodes.every(({ lat, long }: Geocode) => lat === geocodes[0].lat && long === geocodes[0].long)
  }, [ geocodes ])

  /**
   * This method will calculate and affect the boundaries to the map object
   */
  const _affectBoundaries = useCallback(() => {
    if (!map) {
      return
    }

    const bounds: google.maps.LatLngBounds | undefined = GoogleMapsUtils.calculateBounds(geocodes)

    bounds && map.fitBounds(bounds)

    if (_areAllGeocodesTheSame && map.getZoom() !== 17) {
      map.setZoom(17)
    }
  }, [ map, map?.getZoom(), geocodes ])

  /**
   * This method will calculate and affect the center point of the map
   */
  const _affectCenter = useCallback(() => {
    if (!map) {
      return
    }

    map.setCenter(GoogleMapsUtils.calculateCenter(geocodes))
  }, [ map, geocodes ])

  useEffect(() => {
    if (!map || !geocodes?.length) {
      return
    }

    setTimeout(() => {
      _affectCenter()
      _affectBoundaries()
    }, Constants.DEBOUNCE_TIME)
  }, [ map, geocodes, windowSize ])

  if (!isLoaded) {
    return null
  }

  return (
    <ReactGoogleMap
      id={id}
      mapContainerStyle={{
        height: '100%',
        width: '100%'
      }}
      onLoad={_handleOnLoad}
      options={options}
      {...otherProps}
      mapTypeId={'5296ad122bb668a5'}
    >
      {children}
    </ReactGoogleMap>
  )
}

export default GoogleMap
