import { Geocode } from '@eo-storefronts/eo-core'

export default abstract class GoogleMapsUtils {
  static calculateCenter(geocodes: Geocode[]): google.maps.LatLngLiteral {
    const center: google.maps.LatLngLiteral = {
      lat: 50.876838,
      lng: 4.4920918
    }

    if (geocodes.length) {
      geocodes.forEach((geocode: Geocode) => {
        center.lng += Number(geocode.long)
        center.lat += Number(geocode.lat)
      })

      center.lat = center.lat / geocodes.length
      center.lng = center.lng / geocodes.length
    }

    return center
  }

  static calculateBounds(geocodes: Geocode[]): google.maps.LatLngBounds | undefined {
    if (!geocodes) {
      return
    }

    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds()

    geocodes.map((geocode: Geocode) => {
      bounds.extend({
        lat: Number(geocode.lat),
        lng: Number(geocode.long)
      })
    })

    return bounds
  }
}
