import { Marker, MarkerProps } from '@react-google-maps/api'

interface Props extends Omit<MarkerProps, 'icon'> {
  mapMarker?: string,
}

const CustomMapMarker = ({ mapMarker, ...otherProps }: Props) => {
  if (!mapMarker) {
    return (
      <Marker {...otherProps} />
    )
  }

  return (
    <Marker
      icon={{
        url: mapMarker,
        scaledSize: new google.maps.Size(30, 30)
      }}
      {...otherProps} />
  )
}

export default CustomMapMarker
